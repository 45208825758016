import { format } from 'date-fns';

export const PAGE_SIZE_OPTIONS = ['25', '50', '100'];

const date = new Date();

export const now = format(new Date(), 'MMMM dd, yyyy');
export const appVersion: string | undefined =
  process.env.REACT_APP_VERSION_NAME;
export const thisYear = date.getFullYear();

export const getInitialName = (name: string) => {
  const split: string[] = name.split(' ');

  let start = 0;
  let initial = [];

  if (split.length > 1) {
    while (start <= 1) {
      initial.push(split[start].charAt(0));
      start++;
    }
  } else {
    initial.push(split[0].substr(0, 2));
  }

  return initial.join('').toUpperCase();
};

export const formatDate = (date: any) => {
  if (date) {
    const formated = format(new Date(date), 'dd MMM yyyy');
    return formated;
  }
  return '';
};

export const formatYearToTime = (date: any) => {
  if (date) {
    const formated = format(new Date(date), 'dd MMM, yyyy HH:mm');
    return formated;
  }
  return '';
};

export const formatTime = (time: any) => {
  if (time) {
    const formated = format(new Date(time), 'HH:mm');
    return formated;
  }
  return '';
};

export const formatUrlParams = (value: string) => {
  const param = value.split(' ').join('-').toLowerCase();
  return param;
};

export const formatMonth = (date: any) => {
  if (date) {
    const formated = format(new Date(date), 'MMM yyyy');
    return formated;
  }
  return '';
};

export const fixedValueString = (value: number | undefined) => {
  if (!value) {
    return '0';
  }
  return (Math.floor(value * 100) / 100).toFixed(2);
};

export const fixedValueNumber = (value: number) => {
  return parseFloat((Math.floor(value * 100) / 100).toFixed(2));
};

/**
 * massId
 * 42 => Meratus Medan 1
 * 52 => Cargo 1
 */

export const singleEngineAssets = ['42', '52'];

/**
 * massId
 * 15 => TB Kanaka 09
 * 13 => Megawati 20
 * 51 => Forisa 88
 * 43 => 316 Turaco (not included)
 */
export const emsAssets = ['15', '13', '51'];

export const eventEngineAssets = ['103', '142'];

export const subtitleDottedLine =
  'Value of line chart with the dotted line is only estimated data because the data is not yet available';
